<mat-sidenav-container class="example-container">
    <mat-sidenav [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="!mobileQuery.matches" class="primary-bg"
        #sidenav>
        <mat-list role="list" class="menu-list" style="position: relative; height: calc(100vh - 50px);">
            <mat-list-item role="listitem">
                <button mat-flat-button color="primary" class="fill" routerLink="/" routerLinkActive="active">
                    <mat-icon>home</mat-icon>
                    Home
                </button>
            </mat-list-item>
            <mat-list-item role="listitem" *ngFor="let p of pages">
                <button mat-button color="primary" class="fill white-bg" *ngIf="page == p.value">
                    <mat-icon>{{ p.icon }}</mat-icon>
                    {{ p.name }}
                </button>
                <button mat-flat-button color="primary" class="fill" (click)="setPage(p.value)" *ngIf="page != p.value">
                    <mat-icon>{{ p.icon }}</mat-icon>
                    {{p.name}}
                </button>
            </mat-list-item>
            <mat-list-item role="listitem" style="position: absolute; bottom: 0;">
                <button mat-flat-button color="primary" class="fill" (click)="signout()">
                    <mat-icon>exit_to_app</mat-icon>
                    Logout
                </button>
            </mat-list-item>

        </mat-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="page">
            <mat-toolbar>
                <button class="menu-btn" mat-icon-button (click)="sidenav.toggle()">
                    <mat-icon class="menu">menu</mat-icon>
                </button>
                <h2>
                    Recent {{page.charAt(0).toUpperCase()  + page.slice(1)}}
                </h2>
            </mat-toolbar>
            <app-order *ngIf="page == 'orders'"
                [dataFromDashboard]="{tabQuery: tabQuery, mobileQuery: mobileQuery, smallerQuery: smallerQuery}">
            </app-order>
            <app-complaint *ngIf="page == 'complaints'"
                [dataFromDashboard]="{tabQuery: tabQuery, mobileQuery: mobileQuery, smallerQuery: smallerQuery}">
            </app-complaint>
            <app-professinals *ngIf="page == 'professinals'"
                [dataFromDashboard]="{tabQuery: tabQuery, mobileQuery: mobileQuery, smallerQuery: smallerQuery}">
            </app-professinals>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>