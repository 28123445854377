
<mat-form-field appearance="fill">
    <mat-label>Filter Complaint</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Cleaning" [disabled]="isLoading">
    <mat-icon matSuffix>search</mat-icon>
</mat-form-field>

<div *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>

<div class="mat-elevation-z8" *ngIf="!isLoading">
    <table mat-table [dataSource]="dataSource" matSort>

        <!-- ID Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="hover-normal"> ID </th>
            <td mat-cell *matCellDef="let row" class="cursorPointer" (click)="open(row.id)">
                <span class="primary-color">
                    {{row.id}}
                </span>
            </td>
        </ng-container>

        <!-- Name Column -->
        <!-- <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="hover-normal"> Name </th>
            <td mat-cell *matCellDef="let row" class="cursorPointer" (click)="open(row.id)">
                <span class="bold">
                    {{row.name}}
                </span>
            </td>
        </ng-container> -->

        <!-- Created Column -->
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="hover-normal"> Complaint on </th>
            <td mat-cell *matCellDef="let row" class="cursorPointer" (click)="open(row.id)">
                <span>
                    {{row.created | date}}
                </span>
            </td>
        </ng-container>

        <!-- Complaint Column -->
        <ng-container matColumnDef="complaint">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="hover-normal"> Complaint </th>
            <td mat-cell *matCellDef="let row" class="chips-cont cursorPointer" (click)="open(row.id)">
                <span class="wraper">
                    {{row.complaint}}
                </span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>