
<mat-form-field appearance="fill">
    <mat-label>Filter Orders</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Cleaning" [disabled]="isLoading">
    <mat-icon matSuffix>search</mat-icon>
</mat-form-field>


<div *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>

<div class="mat-elevation-z8" *ngIf="!isLoading">
    <table mat-table [dataSource]="dataSource" matSort>

        <!-- ID Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="hover-normal"> ID </th>
            <td mat-cell *matCellDef="let row" class="cursorPointer" (click)="open(row.id)">
                <span class="primary-color">
                    {{row.id}}
                </span>
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="hover-normal"> Name </th>
            <td mat-cell *matCellDef="let row" class="cursorPointer" (click)="open(row.id)">
                <span class="bold">
                    {{row.name}}
                </span>
            </td>
        </ng-container>

        <!-- Created Column -->
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="hover-normal"> Ordered on </th>
            <td mat-cell *matCellDef="let row" class="cursorPointer" (click)="open(row.id)">
                <span>
                    {{row.created | date}}
                </span>
            </td>
        </ng-container>

        <!-- Order Column -->
        <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="hover-normal"> Order </th>
            <td mat-cell *matCellDef="let row" class="chips-cont cursorPointer" (click)="open(row.id)">
                <mat-chip-list>
                    <mat-chip color="primary" selected *ngFor="let or of row.order">
                        <span *ngIf="_typeof(or) == 'string'">
                            {{or}}
                          </span>
                          <span *ngIf="_typeof(or) != 'string'">
                            {{or.toMillis() | date:'MMM d, y'}}
                          </span>
                    </mat-chip>
                </mat-chip-list>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>