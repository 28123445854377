
<div class="page-container" [class.is-mobile]="mobileQuery.matches">
    <mat-toolbar class="toolbar" [class.display-none]="!showHead">
        <div class="logos">
            <img class="logo" src="/assets/get_logo.png" routerLinkActive="active" routerLink="/"/>
          <!--   <img class="logo_simple" src="/assets/logo_simple.png" routerLinkActive="active" routerLink="/"/> -->

        </div>
        

        <mat-toolbar-row>
            <button class="larger-icons" mat-icon-button color="primary" (click)="snav.toggle()"
                [class.display-none]="!mobileQuery.matches">
                <mat-icon>menu</mat-icon>
            </button>
            
            <span class="spacer"></span>
            <div *ngIf="!mobileQuery.matches">
                <button mat-button color="primary" routerLinkActive="active" routerLink="/">
                    <span class="bt">Home</span>
                </button>


                <button mat-button color="primary" [matMenuTriggerFor]="serviecMenu">
                    <span class="bt">Services</span>
                </button>


  

            </div>

            <div *ngIf="!tabQuery.matches">
                <button mat-stroked-button color="primary" *ngIf="!user" routerLinkActive="active" routerLink="/login">
                    <span class="bt">Login</span>
                </button>
                <button mat-button color="primary" *ngIf="user" (click)="logout()">
                    <span class="bt">Logout</span>
                </button>
                <button mat-stroked-button color="primary" *ngIf="user" routerLinkActive="active" routerLink="/dashboard">
                    <span class="bt">Dashboard</span>
                </button>

                <button mat-raised-button color="primary" routerLinkActive="active" routerLink="/register">
                    <mat-icon>account_circle</mat-icon>
                    <span class="bt">Register as Professional</span>
                </button>

            </div>

            <div *ngIf="tabQuery.matches && !smallerQuery.matches">
                <button mat-raised-button color="primary" [matMenuTriggerFor]="menu">
                    <mat-icon>account_circle</mat-icon>
                    <span class="bt">Account</span>
                </button>
            </div>
            <div *ngIf="smallerQuery.matches">
                <button class="larger-icons" mat-icon-button color="primary" aria-label="Accouts"
                    [matMenuTriggerFor]="menu">
                    <mat-icon>account_circle</mat-icon>
                </button>
            </div>
            <mat-menu #menu="matMenu">
                <button mat-menu-item *ngIf="!user"  routerLinkActive="active" routerLink="/login">
                    <mat-icon>offline_pin</mat-icon>
                    <span class="bt">Login</span>
                </button>
                <button mat-menu-item *ngIf="user"  routerLinkActive="active" routerLink="/dashboard">
                    <mat-icon>timeline</mat-icon>
                    <span class="bt">Dashboard</span>
                </button>
                <button mat-menu-item *ngIf="user" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span class="bt">Logout</span>
                </button>
                <button mat-menu-item routerLinkActive="active" routerLink="/register">
                    <mat-icon>assignment</mat-icon>
                    <span class="bt">Register</span>
                </button>
            </mat-menu>
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-menu #serviecMenu="matMenu">
        <button style="min-width: 100px;" mat-menu-item *ngFor="let service of services" routerLinkActive="active" routerLink="/{{service.url}}">
            <span>{{service.name}}</span>
        </button>
    </mat-menu>

    <mat-sidenav-container class="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? showHead ? 70 : 0 : 0">
        <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
            fixedTopGap="56">
            <mat-nav-list>
                <a mat-list-item routerLink="{{ nav.address }}" (click)="snav.close()"
                    *ngFor="let nav of menuNav">{{nav.name}}</a>
            </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content>
            <router-outlet></router-outlet>
            <app-main-footer></app-main-footer>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>