<mat-toolbar>
    <mat-toolbar-row>
        <span>getBalemuya</span>
        <span class="spacer"></span>
        <button mat-button color="primary" routerLink="/" routerLinkActive="active">
            <span class="bt">Home</span>
        </button>
        <button mat-button color="primary" routerLink="/sevices" routerLinkActive="active">
            <span class="bt">Services</span>
        </button>
        <!-- <button mat-button color="primary" routerLink="/about" routerLinkActive="active">
            <span class="bt">About Us</span>
        </button>
        <button mat-button color="primary" routerLink="/contact" routerLinkActive="active">
            <span class="bt">Contact</span>
        </button> -->
        
        <mat-divider></mat-divider>
        <button mat-stroked-button color="primary">
            <span class="bt">Login</span>
        </button>
        <button mat-raised-button color="primary">
            <mat-icon>account_circle</mat-icon>
            <span class="bt">Register</span>
        </button>
    </mat-toolbar-row>
</mat-toolbar>