import { Component} from '@angular/core';


declare var $: any;

@Component({
  selector: 'app-home-clients',
  templateUrl: './clients.component.html'
})
export class HomeClientsComponent {
  constructor() {
  }
}