<div class="fillWindow">
    <div class="head" >
        <h3 class="jkl">
            <span style="color: #F35624">get</span>
            <span style="display: inline-block;">Your Property Managed</span>
        </h3>
        <!-- <h3 class="jkp">
            Gardening service
        </h3> -->
    </div>
    <div class="form">
        <div class="main">
            <div class="loading" *ngIf="loading">
                <mat-spinner></mat-spinner>
            </div>
            <div class="fill">
                <mat-progress-bar *ngIf="tree.length > 1
                                    && tree[1] != 'aaaa'" class="pb" color="accent" mode="determinate" [value]=percentage>
                </mat-progress-bar>
                <!-- <h1 class="form-title">
                    getGardener
                </h1> -->

                <h1 class="current-title">
                    {{ menu[getLast()]['title'] }}
                </h1>
                <p class="current-description">
                    {{ menu[getLast()]['description'] }}
                </p>
                    <h1>Buy, sell, rent, manage, maintain and so on. </h1>

                    <h1> Contact us for more information.</h1>

                        <h1 style="color: #F35624;">0901 173102 | 0901 173202 </h1>
                      
                
            </div>
        </div>
        <div class="ad">
            <div class="asdf">
                <!-- <h3 class="jkl">
                    <span>get</span>
                    <span style="display: inline-block;">Clean</span>
                </h3> -->

                <h3 class="jkp">
                   
                </h3>
            </div>
        </div>

    </div>
</div>
