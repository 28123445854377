<section class="testimonials">
    <h1 class="t-banner" >Testimonials</h1>
    <div class="testimonies">
        <div class="testimony">
            <img src="/assets/m.jpg"
                class="person-img">
            <span class="person-name">
                <h3>Yonas Tadesse</h3>
            </span>
            <span class="t-rate">
                <svg xmlns="http://www.w3.org/2000/svg" width="181.075" height="24" viewBox="0 0 181.075 24">
                    <g id="Rating" transform="translate(-126 -67)">
                        <path id="FontAwsome_star_" data-name="FontAwsome (star)"
                            d="M31.692.822,28.631,7.028l-6.848,1a1.5,1.5,0,0,0-.83,2.559l4.954,4.828-1.172,6.819a1.5,1.5,0,0,0,2.175,1.579l6.126-3.22,6.126,3.22a1.5,1.5,0,0,0,2.175-1.579l-1.172-6.819,4.954-4.828a1.5,1.5,0,0,0-.83-2.559l-6.848-1L34.382.822a1.5,1.5,0,0,0-2.69,0Z"
                            transform="translate(105.5 67.013)" fill="#fd7b53" />
                        <path id="FontAwsome_star_2" data-name="FontAwsome (star)"
                            d="M31.692.822,28.631,7.028l-6.848,1a1.5,1.5,0,0,0-.83,2.559l4.954,4.828-1.172,6.819a1.5,1.5,0,0,0,2.175,1.579l6.126-3.22,6.126,3.22a1.5,1.5,0,0,0,2.175-1.579l-1.172-6.819,4.954-4.828a1.5,1.5,0,0,0-.83-2.559l-6.848-1L34.382.822a1.5,1.5,0,0,0-2.69,0Z"
                            transform="translate(144.5 67.013)" fill="#fd7b53" />
                        <path id="FontAwsome_star_3" data-name="FontAwsome (star)"
                            d="M31.692.822,28.631,7.028l-6.848,1a1.5,1.5,0,0,0-.83,2.559l4.954,4.828-1.172,6.819a1.5,1.5,0,0,0,2.175,1.579l6.126-3.22,6.126,3.22a1.5,1.5,0,0,0,2.175-1.579l-1.172-6.819,4.954-4.828a1.5,1.5,0,0,0-.83-2.559l-6.848-1L34.382.822a1.5,1.5,0,0,0-2.69,0Z"
                            transform="translate(183.5 67.013)" fill="#fd7b53" />
                        <path id="FontAwsome_star_4" data-name="FontAwsome (star)"
                            d="M31.692.822,28.631,7.028l-6.848,1a1.5,1.5,0,0,0-.83,2.559l4.954,4.828-1.172,6.819a1.5,1.5,0,0,0,2.175,1.579l6.126-3.22,6.126,3.22a1.5,1.5,0,0,0,2.175-1.579l-1.172-6.819,4.954-4.828a1.5,1.5,0,0,0-.83-2.559l-6.848-1L34.382.822a1.5,1.5,0,0,0-2.69,0Z"
                            transform="translate(222.5 67.013)" fill="#fd7b53" />
                        <path id="FontAwsome_star_5" data-name="FontAwsome (star)"
                            d="M31.692.822,28.631,7.028l-6.848,1a1.5,1.5,0,0,0-.83,2.559l4.954,4.828-1.172,6.819a1.5,1.5,0,0,0,2.175,1.579l6.126-3.22,6.126,3.22a1.5,1.5,0,0,0,2.175-1.579l-1.172-6.819,4.954-4.828a1.5,1.5,0,0,0-.83-2.559l-6.848-1L34.382.822a1.5,1.5,0,0,0-2.69,0Z"
                            transform="translate(261.5 67.013)" fill="#fd7b53" />
                    </g>
                </svg>

            </span>
            <p>
                 <span style="font-size: 30px; font-weight: 300; color: #fd7b53">"</span>
              I always get confused where to go and whom to contact whenever I had malfunctions on utilities
                and beyoned. Finally ! " <span style="color: #fd7b53;">get</span>Balemuya"
                 <span style="font-size: 30px; font-weight: 300; color: #fd7b53; padding-left: 5px;">"</span>
            </p>
        </div>
        <div class="testimony ">
            <img src="/assets/m.jpg"
                class="person-img " alt=" ">
            <span class="person-name ">
                <h3>Dagem Worku</h3>
            </span>
            <span class="t-rate ">
                <svg xmlns="http://www.w3.org/2000/svg" width="181.591" height="25.023" viewBox="0 0 181.591 25.023">
                    <g id="Rating_half" data-name="Rating half" transform="translate(-126 -66.5)">
                        <path id="FontAwsome_star_" data-name="FontAwsome (star)"
                            d="M31.692.822,28.631,7.028l-6.848,1a1.5,1.5,0,0,0-.83,2.559l4.954,4.828-1.172,6.819a1.5,1.5,0,0,0,2.175,1.579l6.126-3.22,6.126,3.22a1.5,1.5,0,0,0,2.175-1.579l-1.172-6.819,4.954-4.828a1.5,1.5,0,0,0-.83-2.559l-6.848-1L34.382.822a1.5,1.5,0,0,0-2.69,0Z"
                            transform="translate(105.5 67.013)" fill="#fd7b53" />
                        <path id="FontAwsome_star_2" data-name="FontAwsome (star)"
                            d="M31.692.822,28.631,7.028l-6.848,1a1.5,1.5,0,0,0-.83,2.559l4.954,4.828-1.172,6.819a1.5,1.5,0,0,0,2.175,1.579l6.126-3.22,6.126,3.22a1.5,1.5,0,0,0,2.175-1.579l-1.172-6.819,4.954-4.828a1.5,1.5,0,0,0-.83-2.559l-6.848-1L34.382.822a1.5,1.5,0,0,0-2.69,0Z"
                            transform="translate(144.5 67.013)" fill="#fd7b53" />
                        <path id="FontAwsome_star_3" data-name="FontAwsome (star)"
                            d="M31.692.822,28.631,7.028l-6.848,1a1.5,1.5,0,0,0-.83,2.559l4.954,4.828-1.172,6.819a1.5,1.5,0,0,0,2.175,1.579l6.126-3.22,6.126,3.22a1.5,1.5,0,0,0,2.175-1.579l-1.172-6.819,4.954-4.828a1.5,1.5,0,0,0-.83-2.559l-6.848-1L34.382.822a1.5,1.5,0,0,0-2.69,0Z"
                            transform="translate(183.5 67.013)" fill="#fd7b53" />
                        <path id="FontAwsome_star_4" data-name="FontAwsome (star)"
                            d="M31.692.822,28.631,7.028l-6.848,1a1.5,1.5,0,0,0-.83,2.559l4.954,4.828-1.172,6.819a1.5,1.5,0,0,0,2.175,1.579l6.126-3.22,6.126,3.22a1.5,1.5,0,0,0,2.175-1.579l-1.172-6.819,4.954-4.828a1.5,1.5,0,0,0-.83-2.559l-6.848-1L34.382.822a1.5,1.5,0,0,0-2.69,0Z"
                            transform="translate(222.5 67.013)" fill="#fd7b53" />
                        <path id="FontAwsome_star_5" data-name="FontAwsome (star)"
                            d="M31.692.822,28.631,7.028l-6.848,1a1.5,1.5,0,0,0-.83,2.559l4.954,4.828-1.172,6.819a1.5,1.5,0,0,0,2.175,1.579l6.126-3.22,6.126,3.22a1.5,1.5,0,0,0,2.175-1.579l-1.172-6.819,4.954-4.828a1.5,1.5,0,0,0-.83-2.559l-6.848-1L34.382.822a1.5,1.5,0,0,0-2.69,0Z"
                            transform="translate(261.5 67.013)" fill="none" stroke="#fd7b53" stroke-width="1" />
                    </g>
                </svg>

            </span>
            <p>     <span style="font-size: 30px; font-weight: 300; color: #fd7b53">"</span>
                 For the past 4 years I use to have probelem with services providers, untill I found about
                <span style="color: #fd7b53;">get</span>Balemuya
                <span style="font-size: 30px; font-weight: 300; color: #fd7b53">"</span>
            </p>
        </div>
        <div class="testimony ">
            <img src="/assets/f.jpg" class=" person-img ">
            <span class=" person-name ">
                <h3>Emily Smith</h3>
            </span>
            <span class=" t-rate ">
                <svg xmlns=" http://www.w3.org/2000/svg " width=" 181.591 " height=" 25.023 " viewBox=" 0 0 181.591
                25.023 ">
                    <g id=" Rating_half_half " data-name=" Rating half half " transform=" translate(-126 -66.5) ">
                        <path id=" FontAwsome_star_ " data-name=" FontAwsome (star) "
                            d="
                M31.692.822,28.631,7.028l-6.848,1a1.5,1.5,0,0,0-.83,2.559l4.954,4.828-1.172,6.819a1.5,1.5,0,0,0,2.175,1.579l6.126-3.22,6.126,3.22a1.5,1.5,0,0,0,2.175-1.579l-1.172-6.819,4.954-4.828a1.5,1.5,0,0,0-.83-2.559l-6.848-1L34.382.822a1.5,1.5,0,0,0-2.69,0Z "
                            transform=" translate(105.5 67.013) " fill=" #fd7b53 " />
                        <path id=" FontAwsome_star_2 " data-name=" FontAwsome (star) "
                            d="
                M31.692.822,28.631,7.028l-6.848,1a1.5,1.5,0,0,0-.83,2.559l4.954,4.828-1.172,6.819a1.5,1.5,0,0,0,2.175,1.579l6.126-3.22,6.126,3.22a1.5,1.5,0,0,0,2.175-1.579l-1.172-6.819,4.954-4.828a1.5,1.5,0,0,0-.83-2.559l-6.848-1L34.382.822a1.5,1.5,0,0,0-2.69,0Z "
                            transform=" translate(144.5 67.013) " fill=" #fd7b53 " />
                        <path id=" FontAwsome_star_3 " data-name=" FontAwsome (star) "
                            d="
                M31.692.822,28.631,7.028l-6.848,1a1.5,1.5,0,0,0-.83,2.559l4.954,4.828-1.172,6.819a1.5,1.5,0,0,0,2.175,1.579l6.126-3.22,6.126,3.22a1.5,1.5,0,0,0,2.175-1.579l-1.172-6.819,4.954-4.828a1.5,1.5,0,0,0-.83-2.559l-6.848-1L34.382.822a1.5,1.5,0,0,0-2.69,0Z "
                            transform=" translate(183.5 67.013) " fill=" #fd7b53 " />
                        <path id=" FontAwsome_star_4 " data-name=" FontAwsome (star) "
                            d="
                M31.692.822,28.631,7.028l-6.848,1a1.5,1.5,0,0,0-.83,2.559l4.954,4.828-1.172,6.819a1.5,1.5,0,0,0,2.175,1.579l6.126-3.22,6.126,3.22a1.5,1.5,0,0,0,2.175-1.579l-1.172-6.819,4.954-4.828a1.5,1.5,0,0,0-.83-2.559l-6.848-1L34.382.822a1.5,1.5,0,0,0-2.69,0Z "
                            transform=" translate(222.5 67.013) " fill=" none " stroke=" #fd7b53 " stroke-width=" 1 " />
                        <path id=" FontAwsome_star_5 " data-name=" FontAwsome (star) "
                            d="
                M31.692.822,28.631,7.028l-6.848,1a1.5,1.5,0,0,0-.83,2.559l4.954,4.828-1.172,6.819a1.5,1.5,0,0,0,2.175,1.579l6.126-3.22,6.126,3.22a1.5,1.5,0,0,0,2.175-1.579l-1.172-6.819,4.954-4.828a1.5,1.5,0,0,0-.83-2.559l-6.848-1L34.382.822a1.5,1.5,0,0,0-2.69,0Z "
                            transform=" translate(261.5 67.013) " fill=" none " stroke=" #fd7b53 " stroke-width=" 1 " />
                    </g>
                </svg>

            </span>
            <p>     <span style="font-size: 30px; font-weight: 300; color: #fd7b53">"</span>
             You made it so simple to find reliable proffesionals. keep up the good work
                    <span style="font-size: 30px; font-weight: 300; color: #fd7b53">"</span>
            </p>
        </div>



    </div>
</section>

<style>
    .testimonials {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: 'Baloo 2' !important;
        margin-top: 20px;
    }

    .t-banner {
        font-size: 2.5rem;
    }

    .testimonies {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 2rem;
        flex-wrap: wrap;
        position: relative;
    }

    .testimony {
        height: 400px;
        width: 400px;
        padding: 2rem;
        text-align: center;
        transition: all .3s ease;
        border-radius: 10px;
        overflow: hidden;
    }

    .testimony:hover {
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.295), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        transform: translateY(-20px);
    }

    .person-img {
        width: 150px;
        height: 150px;
        padding: 2px;
        border-radius: 50%;
        border: 1px solid black;
    }

    p svg {
        margin: 0 1rem;
    }

    .arrow-container,
    .arrow-container2 {
        padding: .5rem;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: rgba(100, 100, 100, 0.11);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 2;
    }

    .arrow-container {
        position: absolute;
        left: 1rem;
    }

    .arrow-container2 {
        position: absolute;
        right: 2rem;
    }
</style>
