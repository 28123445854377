<h1 mat-dialog-title>Order Detail</h1>
<div mat-dialog-content>
  <div class="basic">
    <p>Order Id <span class="id primary-color">{{order.id}}</span></p>
    <p class="smaller">Order On, {{order.created_at.toMillis() | date:'yyyy/MM/dd h:mm:ss a'}}</p>
  </div>
  <mat-divider class="alltw"></mat-divider>
  <div class="order">
    <h2>Order</h2>
    <mat-chip-list>
      <mat-chip color="primary" selected *ngFor="let or of order.order">
        <span *ngIf="_typeof(or) == 'string'">
          {{or}}
        </span>
        <span *ngIf="_typeof(or) != 'string'">
          {{or.toMillis() | date:'MMM d, y'}}
        </span>
      </mat-chip>
    </mat-chip-list>
    <div>
      <p *ngIf="order.images.length > 0">Images</p>
      <div class="display-image" *ngFor="let image of order.images">
        <a href="{{image}}" target="_blank">
          <img src="{{image}}" />
        </a>
      </div>
    </div>
  </div>
  <mat-divider class="alltw"></mat-divider>
  <div class="contact">
    <h2>Contact Information</h2>
    <p><span class="light">Full Name</span>{{order.contact.fullName}}</p>
    <p><span class="light">Email</span>{{order.contact.email}}</p>
    <p><span class="light">Phone Number</span>{{order.contact.phone}}</p>
    <h2>Location</h2>
    <p><span class="light">Name</span>{{order.location.name ? order.location.name : '--'}}</p>
    <p><span class="light">Coordinate</span>{{order.location.lat}}, {{order.location.lng}}</p>
  </div>

</div>
<!-- <div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button cdkFocusInitial>Ok</button>
</div> -->

<style>
  p {
    margin-bottom: 0;
  }

  h1,
  h2 {
    margin-bottom: 5px !important;
  }

  .id {
    font-weight: 500;
    font-size: 120%;
  }

  .smaller {
    font-size: 90%;
    opacity: 0.7;
  }

  .basic,
  .order,
  .contact {
    margin: 15px 0;
  }

  .contact>p {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 5px;
  }

  .light {
    display: block;
    font-size: 70%;
    opacity: 0.7;
  }

  .alltw {
    margin: 0 -24px;
  }

  .display-image {
    height: 100px;
    width: 100px;
    display: inline-flex;
    align-items: center;
    background-color: #1112;
    margin: 5px;
  }

  .display-image>a>img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
</style>