<div class="all">
    <div class="window">
        <div class="middle">
            <div class="center">
                <mat-card class="mat-elevation-z8">
                    <h1>Logo here</h1>
                    <div class="mask" [ngClass]="!isLoading ? 'none': ''"></div>
                    <mat-card-header>
                        <mat-card-title>
                            <h1>Forgot Password</h1>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput placeholder="Placeholder">
                            <mat-icon matSuffix>alternate_email</mat-icon>
                            <mat-hint>Please enter your email address here</mat-hint>
                        </mat-form-field>
                        <button mat-button type="button" class="alt" color="primary" routerLink='/login'
                            routerLinkActive="active-route">
                            Login
                        </button>
                    </mat-card-content>
                    <mat-card-actions align="end">
                        <span fxFlex class="spacer"></span>
                        <button mat-raised-button type="submit" (click)="send()" color="primary">
                            Send
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</div>