<section class="our-partners">
    <h1>Our Partners</h1>
    <div class="clients">

        <div class="client">
            <div class="img-container">
                <img  class="img-1 op-img"
                    width="120px" height="120px" viewBox="0 0 761 763" src="/assets/HB tech.jpg">
            </div>
        </div>

        <div class="client">
            <div class="img-container">
                    <img  class="img-1 op-img"
                    width="120px" height="120px" viewBox="0 0 761 763" src="/assets/Melkam books.png">

            </div>
        </div>


        <div class="client">
            <div class="img-container">
                <img class="img-1 op-img"
                    width="140px" height="120px" viewBox="0 0 761 763"
                    src="/assets/Ahadu film production.png">
            </div>
        </div>

        <div class="client">
            <div class="img-container">
                <img class="op-img" width="190px" height="150px" src="assets/arki.png">
            </div>
        </div>
        
    </div>
</section>

<style>
    .our-partners {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: "Baloo 2" !important;
    }
    
    .our-partners h1 {
        font-size: 2.5rem;
    }
    
    .clients {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
    }
    
    .img-container {
        width: 300px;
        border-radius: 12px;
        height: 150px;
        padding: 1.5rem;
        background: rgb(234, 235, 236);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        transition: all .5s ease;
    }
    
    img,
    svg {
        transition: all .5s ease;
        pointer-events: none;
    }
    
    .img-c {
        width: 250px;
        height: 190px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    img {
        filter: grayscale(100%) brightness(10%) contrast(50%);
    }
    
    
    /* svg {
        filter: grayscale(100%) brightness(100%) contrast(100%);
    }*/
    
    .img-weird {
        filter: grayscale(100%) brightness(100%) contrast(50%);
    }
    
    .img-1 {
        position: absolute;
        right: 50%;
        left: 50%;
        transform: translateX(-45%);
        filter: grayscale(100%) brightness(100%) contrast(100%);
    }
    
    .img-container:hover {
        transform: scale(1.1);
    }
    
    .img-container:hover img,
    svg {
        filter: none;
    }
    
    .img-container:hover svg {
        filter: none;
    }
</style>