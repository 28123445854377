<div class="container">
    <div class="content">
        <h1>About Us</h1>
        <p>
            Welcome to get balemuya, your number one source for all professional . We're dedicated to providing you the
            best handyman for you home and service, with a focus on dependability. customer service, and reliability.
        </p>
        <p>
            We're working to turn our passion into a booming online platform to get the best proffesionals. We hope you
            enjoy our service as much as we enjoy offering them to you.
        </p>
    </div>
</div>