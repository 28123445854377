import { Component} from '@angular/core';


declare var $: any;

@Component({
  selector: 'app-home-testimonies',
  templateUrl: './testimonies.components.html'
})
export class HomeTestimoniesComponent {
  constructor() {
  }
}